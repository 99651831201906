<template>
    <section :class="postsListClasses" class="posts-list">
        <div class="posts-list__articles">
            <BlockArticle
                class="posts-list__article"
                v-for="article in firstArticles"
                :key="article.code"
                :preload-placeholder="preloadPlaceholder && article.code === firstArticles[0].code"
                v-bind="getArticleProperties(article)" />

            <div class="posts-list__content">
                <slot name="content"
                    v-if="isShowContent"></slot>
            </div>

            <BlockArticle
                class="posts-list__article"
                v-for="article in otherArticles"
                :key="article.code"
                v-bind="getArticleProperties(article)" />
        </div>

        <BasePagination
            v-if="!noPagination"
            class="posts-list__pagination"
            v-model="page"
            :page-range="3"
            :page-count="lastPage"/>
    </section>
</template>

<script>
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import url from '@/filters/url'

import BlockArticle from '@/components/layout/blocks/BlockArticle'

export default {
    name: 'PostsList',
    components: {
        BlockArticle
    },
    props: {
        list: {
            type: Object,
            default: () => {},
            // required: true
        },
        items: {
            type: Array,
            default: () => [],
        },
        preloadPlaceholder:  {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            page: 1,
            isShowContent: false,
            loading: false,
            posts: [],
            lastPage: 1,
            currentPage: 1,
            currentPageUrl: null,
            nextPageUrl: null,
            prevPageUrl: null,
            baseUrl: null,
            perPage: null,
        }
    },
    computed: {
        getUrl() {
            return url.modifyApiQuery(this.baseUrl, {page: this.page});
        },
        noPagination() {
            return this.lastPage <= 1
        },
        postsListClasses() {
            return {
                'posts-list_no-pagination': this.noPagination
            }
        },
        otherArticles() {
            if (this.posts && this.posts.length > 5)
                return this.posts.slice(5, this.posts.length)

            return []
        },
        firstArticles() {
            if (this.posts && this.posts.length)
                return this.posts.slice(0, 5)

            return []
        }
    },
    watch: {
        page() {
            this.load()
        }
    },
    created() {
        this.page = get(this.list, 'currentPage', 1)

        if (this.list) {
            this.read(this.list);
            this.isShowContent = this.page === 1
        } else {
            this.posts = this.items
        }
    },
    methods: {
        get,
        getArticleProperties(article) {
            if (!article) return

            return {
                date: article.createdAt,
                source: article.source,
                entityType: article.entityType,
                code: article.code,
                name: this.getArticleName(article),
                picture: get(article, 'picture.url'),
                alt: get(article, 'picture.alt'),
                title: article.name,
                url: article.htmlUrl,
                userUrl: get(article, 'createdBy.htmlUrl', get(article, 'createdBy[0].htmlUrl', '')),
                section: article.section,
                description: article.anons,
                likes: get(article, 'likes.total'),
                comments: get(article, 'comments.total'),
                views: get(article, 'views.total'),
                coverThumbs: get(article, 'coverThumbs')
            }
        },
        getArticleName(article) {
            return get(article, 'createdBy.fullName') ||
                get(article, 'createdBy[0].fullName', null)
        },
        read(list) {
            if (!list) return

            this.posts = list.items

            this.lastPage = list.lastPage;
            this.currentPage = list.currentPage;
            this.currentPageUrl = list.currentPageUrl;
            this.nextPageUrl = list.nextPageUrl;
            this.prevPageUrl = list.prevPageUrl;

            if (!this.baseUrl)
                this.baseUrl = list.currentPageUrl;

            if (!this.perPage)
                this.perPage = list.perPage;
        },
        /**
         * Запрашивает данные
         *
         * @param  {Boolean} isQuery
         */
        load: debounce(function(isQuery) {
            this.loading = true;

            this.$scrollTo('.posts-list', 1250, {
                offset: -130,
                onDone: () => {
                    this.isShowContent = this.page === 1
                    !this.$url || this.$url.modify({page: this.currentPage}, this.$route.name);
                }
            })

            this.$api.get(this.getUrl)
                .then((response) => {
                    this.read(response.data);
                }).finally(() => {
                    this.loading = false;
                });
        }, 1000),
    }
}
</script>

<style lang="less">
.posts-list {
    padding-bottom: rem(11);

    &__article {
        border-bottom: rem(1) solid @color-gray-white;

        &:nth-child(5) {
            &:not(:last-child) {
                border-bottom: none;
            }
        }
    }

    &__pagination {
        margin-top: rem(11);
    }

    &_no-pagination {
        padding-bottom: 0;

        .posts-list__article {
            &:last-child {
                border-bottom: none;
            }
        }
    }
}
</style>
