<template>
    <section
        class="section-card"
        :class="rootClasses">
        <aside
            class="section-card__leftbar"
            v-if="$slots.leftbar"
            :visible="loaded">

            <slot name="leftbar"></slot>
        </aside>

        <aside
            class="section-card__rightbar"
            v-if="$slots.rightbar"
            :visible="loaded">

            <slot name="rightbar"></slot>
        </aside>

        <div class="section-card__main"
            v-if="$slots.main"
            :visible="loaded">
            <slot name="main"></slot>
        </div>

        <header class="section-card__header"
            v-if="$slots.header">
            <slot name="header"></slot>
        </header>

        <section class="section-card__content"
            :class="contentClasses"
            v-if="$slots.content">
            <slot name="content" ></slot>
        </section>

        <footer class="section-card__footer"
            v-if="$slots.footer">
            <slot name="footer" ></slot>
        </footer>
    </section>
</template>

<script>
import includes from 'lodash/includes'

import { mapGetters } from "vuex";

export default {
    name: 'SectionCard',
    props: {
        loaded: {
            type: Boolean,
            default: true
        },
        noPaddingBottom: {
            type: Boolean,
            default: true
        },
        smallPaddingTop: {
            type: Boolean,
            default: false
        },
        contentLeft: {
            type: [Boolean, String],
            default: false,
            // validator(value) {
            //     return includes(true, false, 'detail', value)
            // }
        },
        sidebar: {
            type: [Boolean, String],
            default: false,
            validator(value) {
                return includes([true, false, 'detail'], value)
            }
        }
    },
    computed: {
        ...mapGetters(['isDeviceDesktop']),
        contentClasses() {
            return {
                'section-card__content_left': this.contentLeft,
                'section-card__content_no-padding-bottom': this.noPaddingBottom,
                'section-card__content_small-padding-top': this.smallPaddingTop
            }
        },
        rootClasses() {
            return {
                'section-card_desktop': this.isDeviceDesktop,
                'section-card_sidebar': this.sidebar,
                'section-card_sidebar-detail': this.sidebar === 'detail',
            }
        }
    }
}
</script>

<style lang="less">
.section-card {
    background-color: @color-white;

    position: relative;

    &__main {
        /* todo: */

        @{mobile} & {
            // .loaded-transition(.15s);
        }

        @{desktop} & {
            grid-area: main;
        }
    }

    &__leftbar,
    &__rightbar {
        position: absolute;
        top: 0;
        z-index: 2;

        @{mobile} & {
            .loaded-transition(.15s);
        }

        @{desktop} & {
            width: 100%;

            position: relative;
        }
    }

    &__leftbar {
        left: rem(24);

        @{desktop} & {
            grid-area: leftbar;

            left: 0;

            .page-block + .page-block {
                margin-top: rem(38);
            }
        }
    }

    &__rightbar {
        right: rem(20);

        @{desktop} & {
            grid-area: rightbar;

            right: 0;
        }
    }

    &__content {
        text-align: center;

        padding: rem(12) rem(20);

        &_left {
            text-align: left;

            padding-bottom: rem(19)
        }

        &_no-padding-bottom {
            padding-bottom: rem(10);
        }

        &_small-padding-top {
            padding-top: rem(2);
            padding-bottom: rem(16);
        }
    }

    &__header {
        text-align: center;

        padding: rem(5) 0 0;
    }

    &__footer {
        text-align: center;

        padding: rem(6) 0 rem(24);
    }

    &__header + &__footer {
        padding-top: rem(16);
    }

    &_sidebar {
        display: grid;
        grid-template-areas:
            'main main'
            'leftbar rightbar';
        grid-template-rows: repeat(2, auto);
        grid-template-columns: minmax(rem(300), 1fr) minmax(rem(706), 1fr);

        column-gap: rem(64);

        padding-top: rem(21);

        &-detail {
            grid-template-columns: minmax(rem(342), 1fr) minmax(rem(616), 1fr);

            column-gap: rem(111);
        }
    }

    @{desktop} &_sidebar &__main {
        padding-bottom: rem(48);
    }
}

</style>
