<i18n>
    {
        "en": {
            "title": "Newsletter subscription",
            "placeholder": "Enter your email",
            "subscription-already-exists": "You are already subscribed"
        },
        "ru": {
            "title": "Подписка на email-рассылку",
            "title_one": "Подписывайся на нашу <br /> рассылку и узнавай всё <br /> самое ",
            "description": "Раз в неделю ты будешь получать интересные подборки статей о парфюмерии, новые ароматы, и полезные лайфхаки!",
            "actual": "актуальное",
            "interesting": "интересное",
            "subscribe": "Подписаться",
            "cognitive": "познавательное",
            "placeholder": "Ваш email",
            "thankyou": "Спасибо!",
            "description_success": "Мы отправили вам письмо! Для завершения подписки проверьте почту и <b>подтвердите свой email.</b>",
            "subscription-already-exists": "Вы уже подписаны"
        }
    }
</i18n>

<template>
    <div class="block-subscribe">
        <div
            class="block-subscribe__form"
            v-if="!success">

            <img class="block-subscribe__icon"
                data-not-lazy
                :alt="$t('title')"
                v-lazy-load data-src="/img/envelop.png"/>

            <div class="block-subscribe__title">
                <!-- eslint-disable-next-line -->
                <span v-html="$t('title_one')"></span>

                <span class="block-subscribe__epithet">
                    <span class="block-subscribe__epithet-wrapper">
                        {{ $t('actual') }}
                        {{ $t('cognitive') }}
                        {{ $t('interesting') }}
                    </span>
                </span>
            </div>

            <div class="block-subscribe__description">
                {{ $t('description') }}
            </div>

            <app-form
                v-slot="{ invalid, pristine, handleSubmit }"
                ref="form">

                <form
                    @submit.prevent="handleSubmit(onSubmit)">

                    <form-field
                        rules="required|email"
                        v-slot="{ errors, failed }"
                        class="form__field block-subscribe__input"
                        vid="email">

                        <div class="form-group" :class="{'form-group_error': failed}">
                            <input type="text"
                                v-model="form.email"
                                @input="onInput"
                                :placeholder="$t('placeholder')"
                                class="form__input form-group__inner" />
                        </div>

                        <span :data-visible="isFailed(failed)" class="form__error">{{ head(errors) }}</span>
                    </form-field>

                    <ButtonBase
                        type="submit"
                        class="block-subscribe__button"
                        :disabled="pristine || invalid">
                        {{ $t('subscribe') }}
                    </ButtonBase>

                </form>
            </app-form>
        </div>
        <div
            class="block-subscribe__success"
            v-else>

            <img
                class="block-subscribe__success-image"
                v-lazy-load data-src="/img/subscribe-success.jpg"
                :alt="$t('thankyou')"/>

            
            <h5 class="block-subscribe__success-title">
                {{ $t('thankyou') }}
            </h5>

            <!-- eslint-disable-next-line -->
            <div class="block-subscribe__success-description" v-html="$t('description_success')"></div>
        </div>
    </div>
</template>

<script>
import head from 'lodash/head'
import Subscription from '@/models/services/mailings/subscriptions'
import ButtonBase from '@/components/ui/buttons/ButtonBase'

export default {
    name: 'BlockSubscribe',
    components: {
        ButtonBase
    },
    data() {
        return {
            form: {
                email: '',
            },
            success: false
        }
    },
    methods: {
        head,
        isFailed(failed) {
            return failed;
        },
        onInput($event) {
            this.form.email = this.form.email.trim();
            this.$refs.form.validate({silent: true});
        },
        onSubmit() {
            new Subscription(this.form)
                .save()
                .then((response) => {
                    this.form.email = null;
                    this.$refs.form.reset();
                    this.success = true
                })
                .catch((error) => {

                    if (error.response.status === 422) {
                        return this.$refs.form.setErrors({
                            'email': this.$t('subscription-already-exists')
                        });
                    };
                });
        }
    }
}
</script>

<style lang="less">
.block-subscribe {
    padding: rem(20);
    .page-padding();

    @{desktop} & {
        border: rem(1) solid @color-gray-white;

        padding: rem(37) rem(32) rem(32);
    }
    
    &__icon {
        margin-bottom: rem(25);

        height: rem(32);
        width: rem(32);

        @{desktop} & {
            margin-bottom: rem(34);

            height: rem(40);
            width: rem(40);
        }
    }

    &__input {
        margin-bottom: rem(16);
    }

    &__title{
        color: @color-black;
        font-size: rem(20);
        font-weight: 500;
        line-height: 130%;

        margin-bottom: rem(12);
    }

    &__epithet {
        content: '';
        display: inline-block;
        vertical-align: bottom;

        color: @color-primary;

        height: rem(26);

        overflow: hidden;

        &-wrapper {
            display: inline-flex;
            vertical-align: bottom;
            flex-direction: column;

            width: rem(170);

            position: relative;

            animation: title 6s infinite linear;
        }
    }

    &__description {
        color: @color-gray;
        font-size: rem(15);

        margin-bottom: rem(20);
    }

    &__button {
        width: 100%;
    }

    &__success {

        &-image {
            display: block;
            width: rem(289);
            margin: 0 auto rem(23);
        }

        &-title {
            font-size: rem(20);
            font-weight: 500;
            line-height: 130%;

            margin-bottom: rem(12);
        }

        &-description {
            color: @color-gray;
            font-size: rem(15);
        }

        @{mobile} & {
            &-image{
                width: 100%;
                margin-bottom: rem(12);
            }

            &-description {
                margin-bottom: rem(4);
            }
        }
    }
}

@keyframes title {
    0% {
        top: 0;
        opacity: 0;
    }

    7% {
        opacity: 1;
    }

    26% {
        opacity: 1;
    }

    33% {
        top: 0;
        opacity: 0;
    }

    35% {
        top: -100%;
        opacity: 0;
    }

    42% {
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    67% {
        top: -100%;
        opacity: 0;
    }

    68% {
        top: -200%;
        opacity: 0;
    }

    72% {
        opacity: 1;
    }

    92% {
        opacity: 1;
    }

    99% {
        top: -200%;
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 0;
    }
}
</style>
