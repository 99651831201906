<i18n>
{
    "ru": {
        "at": "в"
    }
}
</i18n>
<template>
    <div
        class="collections-list">

        <swiper
            class="collections-list__swiper"
            ref="swiper"
            :options="swiperOptions">

            <swiper-slide
                v-for="(section, index) in sections"
                :key="index + get(section, '[0].code', index)">

                <figure
                    v-for="post in section"
                    :key="post.code"
                    class="collections-list__item">

                    <html-link
                        class="collections-list__link"
                        :url="post.htmlUrl"
                        :aria-label="post.name" />

                    <div
                        v-if="post.createdAt"
                        class="collections-list__date">
                        {{ getDate(post.createdAt) }}
                    </div>

                    <!-- eslint-disable-next-line -->
                    <div class="collections-list__title" v-html="post.name"></div>

                </figure>

            </swiper-slide>

            <button
                slot="button-prev"
                v-show="isShowButtons"
                class="swiper-button-prev collections-list__button collections-list__button_prev">
                <svg-icon name="chevron-left" />
            </button>

            <button
                slot="button-next"
                v-show="isShowButtons"
                class="swiper-button-next collections-list__button collections-list__button_next">
                <svg-icon name="chevron-right" />
            </button>

            <div
                class="collections-list__dots swiper__dots"
                v-show="isShowButtons"
                slot="pagination"></div>
        </swiper>

    </div>
</template>

<script>
import chunk from 'lodash/chunk'
import shuffle from 'lodash/shuffle'
import get from 'lodash/get'

export default {
    name: 'CollectionsList',
    props: {
        items: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            swiperOptions: {
                slidesPerView: 1,
                effect: 'fade',
                loop: true,
                // fadeEffect: {
                //     crossFade: true
                // },
                pagination: {
                    el: '.swiper__dots',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                }
            }
        }
    },
    computed: {
        dateTranslations() {
            return {
                lastday_in: this.$t('date.lastday_in'),
                today_in: this.$t('date.today_in'),
                in: this.$t('date.in'),
            }
        },
        sections() {
            return chunk(shuffle(this.items), 4)
        },
        isShowButtons() {
            return this.sections && this.sections.length > 1
        },
    },
    mounted() {
        setTimeout(() => {
            this.$nextTick(() => {
                if (this.$refs.swiper)
                    this.$refs.swiper.updateSwiper()
            })
        }, 0)
    },
    methods: {
        get,
        chunk,
        getDate(value) {
            return this.$options.filters.datePost(value, this.dateTranslations)
        },
    }


}
</script>

<style lang="less">
.collections-list {
    border-bottom: rem(16) solid @color-bg-gray;
    border-top: rem(16) solid @color-bg-gray;

    padding-bottom: rem(7);

    @{desktop} & {
        border: rem(1) solid @color-gray-white;

        padding: 0 rem(32);
    }

    &__swiper {

        @{desktop} & {
            height: 100%;
            min-height: rem(430)
        }

        .swiper{
            &-wrapper{
                height: auto;
                padding-bottom: rem(58);
            }
            &-slide{
                background-color: white;
                height: auto;

                &-active{
                    z-index: 1;
                }
            }
        }
    }

    &__link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    &__item {
        border-bottom: rem(1) solid @color-gray-white;

        margin-bottom: 0;
        padding: rem(14);

        position: relative;
        .page-padding();
    }

    &__item:hover &__title {

        @media @hover {
            color: @color-primary;
        }
    }

    &__date {
        // color: @color-gray-light;
        color: @color-gray-contrast;
        font-size: rem(15);

        margin-bottom: rem(8);
        padding-top: rem(2);

        @{desktop} & {
            margin-bottom: rem(6);
            padding-top: rem(2);
            padding-bottom: rem(0);
        }
    }

    &__title {
        font-size: rem(18);
        font-weight: 500;
        line-height: 1.5;

        margin-bottom: 0;
        // .text-ellipsis(@rows)
    }

    &__button {
        background: @color-gray-light-2;
        border: none;
        border-radius: 50%;
        color: @color-black;

        padding: 0;

        height: rem(36);
        width: rem(36);

        top: auto;
        bottom: rem(7);

        transition: opacity .2s ease, background-color .15s ease;

        .hover({
            background-color: @color-gray-white;
        });

        @{desktop} & {
            height: rem(26);
            width: rem(26);

            bottom: rem(15);
        }

        svg {
            height: rem(16);
            width: rem(16);
        }

        &_prev {
            left: @dim-page-padding;

            @{desktop} & {
                lefT: rem(1);
            }
        }

        &_next {
            right: @dim-page-padding;

            @{desktop} & {
                right: rem(1);
            }
        }

        &:after,
        &:before {
            display: none;
        }
    }

    &__dots {
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 1;

        .swiper-pagination-bullet {
            background-color: @color-gray-2;

            .hover({
                background-color: @color-gray-light;
            });

            &-active {
                background-color: @color-black;
            }
        }

        @{desktop} & {
            bottom: 0;
            height: rem(58);
        }

        @{mobile} & {
            bottom: rem(-7);
            height: rem(64);
        }
    }
}

</style>
